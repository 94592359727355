import React, { Component } from "react";
import "./ThankYou.css";
export class ThankYou extends Component {
  render() {
    return (
      <div className="thankyou__div bg-warning  justify-content-center">
        <center>
          <h3>THANK YOU</h3>
        </center>
        <p className="lead">
          Our deepest gratitude for all the donors who made it possible for us
          to provide a financial assistance for the holiday. Because of our
          generous donors a total of 260 households have received the aid.
        </p>
      </div>
    );
  }
}

export default ThankYou;
