import React, { Component, useState } from "react";

import "./ThankYou.css";
import hope from "../../images/hope.jpg";
import easter from "../../images/easter2.jpg";
import zelle from "../../images/zelle.png";
import newyear from "../../images/newyear.jpg";
import dyk from "../../images/dyk.png";

import ProgressBar from "react-bootstrap/ProgressBar";
import StripeContainer from "./StripeContainer";

export class Easter extends Component {
  // returnRaisedAmount() {
  //   const [raisedAmount, setRaisedAmount] = useState(0);
  // }
  render() {
    return (
      <div className="row bg-gray  justify-content-center">
        <div className=" col-md-12 ">
          <div
            className="downarrowdiv text-center "
            id=""
            style={{ width: "100%", transparent: true, opacity: 0.6 }}
          >
            <h1 className=" font-weight-bold">
              <span className="text-white  font-weight-bold">
                Hunger Free
                <span className="text-warning">
                  &nbsp; Enkutatash 2015 &nbsp;Campaign
                </span>
              </span>
            </h1>
          </div>

          <div className="col-md-12 py-2 mt-2">
            <ProgressBar
              animated
              now={(2574.87 / 7000) * 100}
              label={` ${((2574.87 / 7000) * 100).toPrecision(3)} %`}
              // label="Closed"
              style={{
                width: "100%",
                height: "30px",
              }}
            />

            <div className="text-primary font-weight-bold">
              <span
                className=""
                style={{
                  fontFamily: "Courier",
                  float: "left",
                  width: "50%",
                }}
              >
                Raised: $2,574.87 (
                {`${((2574.87 / 7000) * 100).toPrecision(3)}%`})
              </span>
              <span
                className=""
                style={{
                  fontFamily: "Courier",
                  float: "right",
                  width: "50%",
                }}
              >
                Goal: $7,000.00
              </span>
            </div>

            {/* <div className="text-warning font-weight-bold"></div> */}
          </div>

          <div className="row">
            <div className="col-md-6">
              <p className="lead justify rounded">
                Hope 4 Tebassie is currently raising funds to provide meals for
                the Tebassie community in Debre Birhan Ethiopia. Our{" "}
                <span className="text-dark  font-weight-bold">
                  Hunger Free Enkutatsh 2015 Campaign&nbsp;
                </span>
                target is to reach 1000 individuals. We believe that with your
                generosity and empathy for those who are in need, we can provide
                meals to 1000 individuals. In view of this, your donation of
                $25,$50, $75, $100 or whatever your heart desires will be
                greatly appreciated. We thank you in advance for supporting this
                blessed initiative and for fulfilling our moral obligations. We
                thank you for your support!
              </p>
              <p className="display-5 lead font-weight-bold">
                Please donate via
                <img
                  src={zelle}
                  alt=""
                  style={{ height: "70%", width: "10%", align: "center" }}
                />
                <span style={{ color: "blue" }}>hope4tebassie@gmail.com</span>
              </p>
            </div>
            <div className=" col-md-6">
              <p className="lead">
                <img
                  src={newyear}
                  width="100%"
                  height="250px"
                  style={{
                    borderradius: "35px 0 0 0",
                  }}
                />
              </p>

              <div className="row p-0">
                <div className="col-md-3 p-1 " align="center">
                  <img
                    src={dyk}
                    alt=""
                    style={{
                      height: "75px",
                      width: "75px",
                      align: "center",
                      marginTop: "75px",
                    }}
                  />
                </div>
                <div className="col-md-9 m-0 p-0 bg-white">
                  <h6 className="lead text-justify p-3 ">
                    Ethiopia is 7 years behind and the first month of the
                    Calendar year is September? Yes! Ethiopia will be welcoming
                    2015 on September 11, 2022.
                  </h6>
                  <ul>
                    <li>
                      <a
                        href="https://ethiopiancalendar.wordpress.com/history/"
                        target="_blank"
                      >
                        The Ethiopic Calendar
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://theculturetrip.com/africa/ethiopia/articles/why-is-the-ethiopian-calendar-7-years-behind/"
                        target="_blank"
                      >
                        Why Is the Ethiopian Calendar 7 Years Behind?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="bg-light col-sm-8 p-4 mt-2">
            <StripeContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default Easter;
