import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import "react-toastify/dist/ReactToastify.css";

import "./PaymentForm.css";

toast.configure();

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      width: "10%",
      iconColor: "lightgray",
      color: "#000",
      border: "10px solid",
      fontWeight: 500,
      fontFamily: "Roboto,Open Sans,Segoe UI,sans-serif",
      fontSize: "12px",
      padding: "10px",
      marginTop: "10px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#000" },
      "::placeholder": { color: "gray" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

export default function PaymentForm() {
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [donationDescription, setDonationDescription] = useState();

  const [fee, setFee] = useState(0);
  const [netDonation, setNetDonation] = useState("");
  const [totalDonate, setTotalDonate] = useState(0);

  const [isDisabled, setIsDisabled] = useState(true);
  const [coverFee, setCoverFee] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onDonationAmountChange = (e) => {
    e.preventDefault();

    setIsDisabled(true);
    setAmount(e.target.value);

    if (!coverFee) {
      if (e.target.value > 0) {
        setFee(parseFloat(e.target.value * 0.029 + 0.3).toPrecision(3));
        setNetDonation(e.target.value - (e.target.value * 0.029 + 0.3));
        setTotalDonate(e.target.value);
      } else {
        setFee(0);
        setNetDonation(0);
        setTotalDonate(0);
      }
    } else {
      if (e.target.value > 0) {
        setFee(parseFloat(e.target.value * 0.029 + 0.3).toPrecision(3));
        setNetDonation(
          (
            parseFloat(e.target.value) +
            parseFloat(e.target.value * 0.029 + 0.3)
          ).toPrecision(5)
        );
        setTotalDonate(
          parseFloat(e.target.value) +
            parseFloat((e.target.value * 0.029 + 0.3).toPrecision(3))
        );

        // setTotalDonate(
        //   (
        //     parseFloat(e.target.value) +
        //     parseFloat(e.target.value * 0.029 + 0.3)
        //   ).toPrecision(5)
        // );
      } else {
        setFee(0);
        setNetDonation(0);
        setTotalDonate(0);
      }
    }
  };

  function handleDonationDescription(e) {
    setDonationDescription(e.target.value);
  }

  function handleFee() {
    setCoverFee(!coverFee);
    if (!coverFee) {
      if (amount > 0) {
        setTotalDonate(parseFloat(amount) + parseFloat(fee));
      }
    } else {
      setTotalDonate(parseFloat(amount));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      setProcessing(true);

      try {
        const { id } = paymentMethod;
        // console.log("paymentMethod>>>>>>>>>>>.", paymentMethod);

        const local__endpoint =
          "https://us-central1-hope4tebassie-d74ed.cloudfunctions.net/StripeApi/payment";
        //"http://localhost:5001/hope4tebassie-d74ed/us-central1/StripeApi/payment";

        const response = await axios.post(local__endpoint, {
          amount: totalDonate * 100,
          id,
          email: email,
          nameOnCard: name,
          donationDescription: donationDescription,
        });

        if (response.data.success) {
          // console.log("Successfull payment");
          toast("Success! Check email for details", { type: "success" });
          setSuccess(true);
        } else {
          if (totalDonate <= 0) {
            toast("Sorry, donation amount should be greater than zero", {
              type: "error",
            });
          } else {
            toast("Sorry, something went wrong please try again", {
              type: "error",
            });
          }
          setSuccess(false);
          setProcessing(false);
        }
      } catch (error) {
        console.log("Error", error);
        toast("Sorry, something went wrong please try again", {
          type: "error",
        });
      }
    } else {
      console.log(error.message);
      toast("Sorry, something went wrong please try again", {
        type: "error",
      });
    }
  };
  return (
    <>
      {!success ? (
        <div className="container">
          <form onSubmit={handleSubmit}>
            <h5 className="">Please select or enter your donation amount</h5>

            <div class="bg-warning  text-center font-weight-normal">
              <div class=" form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="DonationType"
                  id="inlineRadio1"
                  value="Donation"
                  onChange={handleDonationDescription}
                />
                <label class="form-check-label" for="inlineRadio1">
                  <h6>Donation </h6>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="DonationType"
                  id="inlineRadio2"
                  value="MonthlyContribution"
                  onChange={handleDonationDescription}
                />
                <label class="form-check-label" for="inlineRadio2">
                  <h6>Monthly Contribution</h6>
                </label>
              </div>
            </div>
            <div className="amount bold">
              <div className="amount__button">
                <button
                  className="btn btn-outline-warning "
                  value={25}
                  onClick={onDonationAmountChange}
                >
                  $25
                </button>
              </div>
              <div className="amount__button">
                <button
                  className="btn btn-outline-warning "
                  value={50}
                  onClick={onDonationAmountChange}
                >
                  $50
                </button>
              </div>
              <div className="amount__button">
                <button
                  className="btn btn-outline-warning "
                  value={75}
                  onClick={onDonationAmountChange}
                >
                  $75
                </button>
              </div>
              <div className="amount__button">
                <button
                  className="btn btn-outline-warning "
                  value={100}
                  onClick={onDonationAmountChange}
                >
                  $100
                </button>
              </div>
              <div className="amount__button">
                <button
                  className="btn btn-outline-warning "
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      setAmount(0);
                      setFee(0);
                      setNetDonation(0);
                      setTotalDonate(0);
                      setIsDisabled(false);
                    }
                  }}
                >
                  Other
                </button>
              </div>
            </div>
            <p className="lead">
              Donation amount: $
              <input
                style={{ width: "100px", outline: "red" }}
                type="number"
                disabled={isDisabled}
                value={amount}
                decimalScale={2}
                onChange={(e) => {
                  e.preventDefault();

                  // setIsDisabled(true);
                  if (!coverFee) {
                    setAmount(e.target.value);
                    if (e.target.value > 0) {
                      setFee(
                        parseFloat(e.target.value * 0.029 + 0.3).toPrecision(3)
                      );
                      setNetDonation(
                        e.target.value - (e.target.value * 0.029 + 0.3)
                      );
                      setTotalDonate(e.target.value);
                    } else {
                      setFee(0);
                      setNetDonation(0);
                      setTotalDonate(0);
                    }
                  } else {
                    setAmount(e.target.value);
                    if (e.target.value > 0) {
                      setFee(
                        parseFloat(e.target.value * 0.029 + 0.3).toPrecision(3)
                      );
                      setNetDonation(
                        (
                          parseFloat(e.target.value) +
                          parseFloat(e.target.value * 0.029 + 0.3)
                        ).toPrecision(5)
                      );

                      setTotalDonate(
                        (
                          parseFloat(e.target.value) +
                          parseFloat(e.target.value * 0.029 + 0.3)
                        ).toPrecision(5)
                      );
                    } else {
                      setFee(0);
                      setNetDonation(0);
                      setTotalDonate(0);
                    }
                  }
                  //{
                  //   setAmount(e.target.value);
                  //   if (e.target.value > 0) {
                  //     if ({ coverFee }) {
                  //       setFee(e.target.value * 0.029 + 0.3);
                  //       setNetDonation(
                  //         e.target.value - (e.target.value * 0.029 + 0.3)
                  //       );
                  //     } else {
                  //       setFee(0);
                  //       setNetDonation(0);
                  //     }
                  //   }
                }}
              />
            </p>
            <p
              className="lead btn btn-warning"
              style={{ width: "", fontSize: "bolder" }}
            >
              <strong>
                <div class="form-check ">
                  <input
                    id="flexCheckChecked"
                    onChange={handleFee}
                    type="checkbox"
                    value="fee"
                  />

                  <label class="form-check-label" for="flexCheckChecked">
                    <CurrencyFormat
                      renderText={(fee) => (
                        <>
                          <h6>Add {fee} to help cover the fees.</h6>
                        </>
                      )}
                      decimalScale={2}
                      value={fee}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </label>
                </div>
              </strong>
            </p>

            <strong>
              <div class="">
                <CurrencyFormat
                  renderText={(totalDonate) => (
                    <>
                      <h6>Total Donation amount: {totalDonate}</h6>
                    </>
                  )}
                  decimalScale={5}
                  value={totalDonate}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            </strong>

            {/* <p className="lead">
              Fee: $
              <input
                style={{ width: "100px", outline: "red" }}
                type="number"
                step="0.01"
                // precision={2}
                disabled={true}
                value={fee}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              Net: $
              <input
                style={{ width: "100px", outline: "red" }}
                type="number"
                step="0.01"
                // precision={2}
                disabled={true}
                value={netDonation}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </p> */}

            <h5 className="lead">E-mail </h5>
            <input
              required
              style={{ width: "250px", outline: "red" }}
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                console.log(amount);
                console.log(totalDonate);
              }}
            />

            <h5 className="lead">Name on Card </h5>
            <input
              style={{ width: "250px", outline: "red" }}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <h5 className="lead">Card Details </h5>
            <div className="border">
              <CardElement options={CARD_OPTIONS} />
            </div>

            <button className="btn btn-warning center">
              {processing ? "Processing..." : "Donate"}
            </button>
          </form>

          {/* <form onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={CARD_OPTIONS} />
              </div>
            </fieldset>
          </form> */}
        </div>
      ) : (
        <div>
          <h5 className=" text-success">
            Thank you for your generous donation.
          </h5>
        </div>
      )}
    </>
  );
}
