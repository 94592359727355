import React, { Component } from "react";
import "./ThankYou.css";
import hope from "../../images/hope.jpg";
import easter from "../../images/easter2.jpg";
import zelle from "../../images/zelle.png";
import ScanToPayEaster from "../../images/ScanToPayEaster.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import StripeContainer from "./StripeContainer";

export class Easter extends Component {
  render() {
    return (
      <div className=" bg-gray  justify-content-center">
        <div className=" col-md-12 ">
          <div
            className="downarrowdiv text-center "
            id=""
            style={{ width: "100%" }}
          >
            <h1 className=" font-weight-bold">
              <span className="text-white  font-weight-bold">
                Feed the Hungry
                <span className="text-warning">
                  &nbsp; Easter Meal Campaign 2015 &nbsp;
                </span>
              </span>
            </h1>
          </div>

          <p className="lead">
            <img
              src={easter}
              width="100%"
              height="250px"
              style={{
                borderradius: "35px 0 0 0",
              }}
            />
            <div className="col-sm-12 py-2 mt-2">
              <ProgressBar
                animated
                now={(3125 / 6000) * 100}
                label={` ${((3125 / 6000) * 100).toPrecision(3)} %`}
                // label="Closed"
                style={{
                  width: "100%",
                  height: "30px",
                }}
              />

              <div className="text-primary font-weight-bold">
                <span
                  className=""
                  style={{ fontFamily: "Courier", float: "left", width: "50%" }}
                >
                  Raised:$3,125 ({`${((3125 / 6000) * 100).toPrecision(3)}%`})
                </span>
                <span
                  className=""
                  style={{
                    fontFamily: "Courier",
                    float: "right",
                    width: "50%",
                  }}
                >
                  Goal: $6,000.00
                </span>
              </div>

              {/* <div className="text-warning font-weight-bold"></div> */}
            </div>
          </p>
          <div align="justify">
            <p className="justify rounded">
              Hope 4 Tebassie is currently raising funds to provide Easter meals
              for the Tebassie community in Debre Birhan, Ethiopia. We believe
              that with your generosity, we can provide meals to everyone in
              need.
            </p>
            <p>
              We are currently seeking donations of $25, $50, $75, or $100 to
              help us reach our goal of feeding 1,000 people. Your donation will
              help us purchase food, cooking supplies, and other necessary
              items.
            </p>
            <p> We appreciate your generosity and support!</p>

            <p className="lead text-primary font-weight-bolder justify rounded">
              Make your 100% tax-deductible charitable donation now.
            </p>
            <center>
              <a href="https://donate.stripe.com/14k6scclm5719wIfZ1">
                <button
                  className="btn btn-warning my-2 my-sm-0 text-uppercase"
                  role="link"
                >
                  Donate
                </button>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={ScanToPayEaster}
                width="125px"
                height="150px"
                style={{
                  borderradius: "35px 0 0 0",
                }}
              />
            </center>
            <p className="display-5 lead font-weight-bold">
              Please donate via
              <img
                src={zelle}
                alt=""
                style={{ height: "70%", width: "10%", align: "center" }}
              />
              <span style={{ color: "blue" }}>hope4tebassie@gmail.com</span>
            </p>
          </div>
          <hr></hr>
          {/* <div className="bg-light col-sm-8 p-4 mt-2">
          <StripeContainer />
        </div> */}
        </div>
      </div>
    );
  }
}

export default Easter;
