import React, { Component } from "react";
import "./ThankYou.css";
import hope from "../../images/hope.jpg";
import easter from "../../images/easter2.jpg";
import scantopay from "../../images/ScanToPay.png";
import FeedKidsSlider from "./FeedKidsSlider";

import zelle from "../../images/zelle.png";
import "./blurImage.css";

import ProgressBar from "react-bootstrap/ProgressBar";
import StripeContainer from "./StripeContainer";

export class Anti_Hunger_Initiative extends Component {
  render() {
    return (
      <div className="   justify-content-center">
        <div className=" col-md-12 ">
          {/* <div
            className="downarrowdiv text-center "
            id=""
            style={{ width: "100%" }}
          >
            <h1 className=" font-weight-bold">
              <span className="text-white  font-weight-bold">
                Anti Hunger Initiative
                <span className="text-warning">
                  &nbsp; Feed One Child &nbsp;
                </span>
              </span>
            </h1>
          </div> */}

          <div class="bg-text" style={{ marginTop: "200px" }}>
            <h1 className=" font-weight-bold">
              <span className="text-white  font-weight-bold">
                Anti-Hunger Initiative
                <span className="text-warning">
                  <p>Feed One Child</p>
                </span>
              </span>
            </h1>
          </div>
          <p className="lead">
            {/* <FeedKidsSlider /> */}

            {/* <img
              src={easter}
              width="100%"
              height="250px"
              style={{
                borderradius: "35px 0 0 0",
              }}
            /> */}
          </p>
          <div
            align="center"
            className=" justify rounded text-dark bg-white"
            style={{
              backgroundColor: "lightyellow",
              marginTop: "-500px",
              align: "center",
              // paddingRight: "200px",
              // paddingLeft: "200px",
              "z-index": 5,
            }}
          >
            <p className="lead justify">
              Your gift could be the difference between a child going to school
              hungry or eating a nutritious meal. Hope 4 Tebassie(H4T) works to
              provide meals in schools to children most in need.
            </p>
            <p className=" lead justify rounded">
              It’s easy for you to make a life saving difference. $30 can
              provide 2 meals per school day for a child for an entire month.
              You have the power to save lives.
            </p>
            <p className="lead text-primary font-weight-bolder justify rounded">
              Make your 100% tax-deductible charitable donation now.
            </p>
            <center>
              <a href="https://buy.stripe.com/7sI17S99a42XaAMfZ0">
                <button
                  className="btn btn-warning my-2 my-sm-0 text-uppercase"
                  role="link"
                >
                  Donate
                </button>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={scantopay}
                width="125px"
                height="150px"
                style={{
                  borderradius: "35px 0 0 0",
                }}
              />
            </center>

            <p className="display-5 lead font-weight-bold">
              Please donate via
              <img
                src={zelle}
                alt=""
                style={{ height: "70%", width: "10%", align: "center" }}
              />
              <span style={{ color: "blue" }}>hope4tebassie@gmail.com</span>
            </p>
          </div>
          <hr></hr>
          {/* <div className="bg-light col-sm-8 p-4 mt-2">
          <StripeContainer />
        </div> */}
        </div>
      </div>
    );
  }
}

export default Anti_Hunger_Initiative;
