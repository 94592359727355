import React, { Component } from "react";
import feed11 from "../../images/feed1.jpg";
import feed21 from "../../images/feed2.jpg";
import feed31 from "../../images/feed3.jpg";
import feed41 from "../../images/feed4.jpg";
import feed51 from "../../images/feed5.jpg";
import "./blurImage.css";

export class FeedKidsSlider extends Component {
  render() {
    return (
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
        data-interval="3000"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100  bg-image" src={""} alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100  bg-image" src={""} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100  bg-image" src={""} alt="Third slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100  bg-image" src={""} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100  bg-image" src={""} alt="Third slide" />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

        {/* <div class="bg-text">
          <h1>I am John Doe</h1>
          <p>And I'm a Photographer</p>
        </div> */}
      </div>
    );
  }
}

export default FeedKidsSlider;
