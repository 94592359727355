// import "./App.css";
import h4t from "../../images/logo11.png";

import StripeContainer from "./StripeContainer";
import NotFoundPage from "./NotFoundPage";
import Payment from "./Payment";
import React, { useState } from "react";
import Login from "./Login";
import Education from "../../images/education.jpg";

import "./Donate.css";
function Donate() {
  return (
    <div className="container ">
      <div className="row">
        <div className=" col-md-8 ">
          <div className="bg-light text-dark p-5" style={{ marginTop: "50px" }}>
            <div className="text-center " id="" style={{ width: "100%" }}>
              <h1>
                <span className="font-weight-bold">Let's Transform Lives</span>
              </h1>
            </div>
            <p className="lead text-justify">
              You will be making a difference in the lives of children and
              families in Tebassie. Whether it is meeting an immediate need of a
              vunlerable child, helping strengthen families, or providing tools
              for career success for young adults, your gift helps inspire
              change.
            </p>
            <p className="lead text-justify">
              We thank you in advance for supporting our efforts! We cannot do
              this without generous friends and family like you.
            </p>
            <h5 className=" center">Donate Today</h5>
            <br /> <br /> <br /> <br />
          </div>
        </div>

        <div
          className=" col-sm-4 p-4 mt-5 border-2 "
          // style={{ backgroundColor: "#343A40", fontcolor: "#FFC30C" }}
        >
          {/* <img
            src={h4t}
            alt=""
            style={{ height: "100px", width: "100px", align: "center" }}
          /> */}
          {/* <NotFoundPage /> */}
          <StripeContainer />
          {/* <Payment /> */}
        </div>
      </div>
    </div>
  );
}

export default Donate;
