import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./Payment.css";

toast.configure();

function Payment() {
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const onDonationAmountChange = (e) => {
    e.preventDefault();

    setIsDisabled(false);

    setAmount(e.target.value);
  };

  async function handleToken(token, addresses) {
    const response = await axios.post("http://localhost:4000/checkout", {
      token,
      amount,
    });
    const { status, receipt_url } = response.data;
    console.log("Response:", response.data);
    if (status === "success") {
      setSuccess(true);
      toast("Success! Check email for details", { type: "success" });
    } else {
      toast("Something went wrong", { type: "error" });
    }
  }

  return (
    <>
      {!success ? (
        <div className="">
          <h5 className="lead">Select an amount</h5>
          <div className="amount">
            <div className="amount__button">
              <button
                className="btn btn-outline-warning "
                value={25}
                onClick={onDonationAmountChange}
              >
                $25
              </button>
            </div>
            <div className="amount__button">
              <button
                className="btn btn-outline-warning "
                value={50}
                onClick={onDonationAmountChange}
              >
                $50
              </button>
            </div>
            <div className="amount__button">
              <button
                className="btn btn-outline-warning "
                value={75}
                onClick={onDonationAmountChange}
              >
                $75
              </button>
            </div>
            <div className="amount__button">
              <button
                className="btn btn-outline-warning "
                value={100}
                onClick={onDonationAmountChange}
              >
                $100
              </button>
            </div>
            <div className="amount__button">
              <button
                className="btn btn-outline-warning "
                onClick={(e) => {
                  {
                    e.preventDefault();
                    setAmount(0);
                    if (amount > 0) {
                      setIsDisabled(false);
                    }
                  }
                }}
              >
                Other
              </button>
            </div>
          </div>
          <p className="lead">
            Your donation: $
            <input
              style={{ width: "100px", outline: "red" }}
              type="number"
              disabled={isDisabled}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </p>
          <div className="container">
            <StripeCheckout
              // stripeKey="pk_test_51IdHtHBNbE8tP4g1xz8qrPr84qGZqhmeqb8a1hNKCv2PXmFwPe6GgMNcWE21OJaUOuUMkYxXvNHilWzwq4C8CVNy00UM0jZZ4M"
              stripeKey="pk_live_51IdHtHBNbE8tP4g1GgnMeVA5XolW8BQw2M8TRrAZbMwB585tqpepJ3JwcjxFnsvoC1lMCUewqrQxzxDMqzZhYVDq00QYGqJLaA"
              token={handleToken}
              amount={amount * 100}
              name="Hope 4 Tebassie Donation"
              billingAddress
              label="Donate"
            />
          </div>
        </div>
      ) : (
        <div>
          <h5>Thank you for your generous donation</h5>
        </div>
      )}
    </>
  );
}

export default Payment;
