import React, { Component } from "react";

export class Give828 extends Component {
  render() {
    return (
      <div>
        <iframe
          width={300}
          height={500}
          src="https://www.give828.org/forms/Hope-4-Tebassie?id=8pjnff&embed=donation_widget"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          frameBorder="0"
          style={{
            marginLeft: "-15px",
          }}
        ></iframe>

        {/* <iframe
          width={300}
          height={500}
          style={{
            width: "300px",
            height: "500px",
            border: 0,
            scrolling: false,

            margin: "0px",
          }}
          src="https://www.give828.org/forms/Hope-4-Tebassie?id=8pjnff&embed=donation_widget"
          // scrolling="no"
          // marginHeight="0"
          // marginWidth="0"
          // frameBorder="0"
        ></iframe> */}
      </div>
    );
  }
}

export default Give828;
