import React, { Component } from "react";
// import styled from 'styled-components';
import causesImage from "../../images/causes.png";
import easter from "../../images/easter-card.jpg";
import vicemayor__speech from "../../images/Vice mayor Speech.mp4";
import newyear from "../../images/newyear.jpg";
import zelle from "../../images/zelle.png";
import dyk from "../../images/dyk.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Causes.css";
import styled from "styled-components";
import DonateButton from "../layout/DonateButton";
import Easter from "./Easter";
class Causes extends Component {
  render() {
    return (
      <div>
        <CausesContainer className="my-0 p-5 bg-light">
          <div className="container ">
            <div className="row ">
              <div className=" col-md-12 ">
                <div
                  className="downarrowdiv text-center "
                  id=""
                  style={{ width: "100%", transparent: true, opacity: 0.6 }}
                >
                  <h1 className=" font-weight-bold">
                    <span className="text-white  font-weight-bold">
                      Hunger Free
                      <span className="text-warning">
                        &nbsp; Enkutatash 2015 &nbsp;Campaign
                      </span>
                    </span>
                  </h1>
                </div>

                <div className="col-md-12 py-2 mt-2">
                  <ProgressBar
                    animated
                    now={(2574.87 / 7000) * 100}
                    // label={` ${((2574.87 / 7000) * 100).toPrecision(3)} %`}
                    label="Closed"
                    style={{
                      width: "100%",
                      height: "30px",
                    }}
                  />

                  <div className="text-primary font-weight-bold">
                    <span
                      className=""
                      style={{
                        fontFamily: "Courier",
                        float: "left",
                        width: "50%",
                      }}
                    >
                      Raised: $2,574.87 (
                      {`${((2574.87 / 7000) * 100).toPrecision(3)}%`})
                    </span>
                    <span
                      className=""
                      style={{
                        fontFamily: "Courier",
                        float: "right",
                        width: "50%",
                      }}
                    >
                      Goal: $7,000.00
                    </span>
                  </div>

                  {/* <div className="text-warning font-weight-bold"></div> */}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <p className="lead justify rounded">
                      Hope 4 Tebassie is currently raising funds to provide
                      meals for the Tebassie community in Debre Birhan Ethiopia.
                      Our{" "}
                      <span className="text-dark  font-weight-bold">
                        Hunger Free Enkutatsh 2015 Campaign&nbsp;
                      </span>
                      target is to reach 1000 individuals. We believe that with
                      your generosity and empathy for those who are in need, we
                      can provide meals to 1000 individuals. In view of this,
                      your donation of $25,$50, $75, $100 or whatever your heart
                      desires will be greatly appreciated. We thank you in
                      advance for supporting this blessed initiative and for
                      fulfilling our moral obligations. We thank you for your
                      support!
                    </p>
                  </div>
                  <div className=" col-md-6">
                    <p className="lead">
                      <img
                        src={newyear}
                        width="100%"
                        height="250px"
                        style={{
                          borderradius: "35px 0 0 0",
                        }}
                      />
                    </p>

                    <div className="row p-0">
                      <div className="col-md-3 p-1 " align="center">
                        <img
                          src={dyk}
                          alt=""
                          style={{
                            height: "75px",
                            width: "75px",
                            align: "center",
                            marginTop: "75px",
                          }}
                        />
                      </div>
                      <div className="col-md-9 m-0 p-0 bg-white">
                        <h6 className="lead text-justify p-3 ">
                          Ethiopia is 7 years behind and the first month of the
                          Calendar year is September? Yes! Ethiopia will be
                          welcoming 2015 on September 11, 2022.
                        </h6>
                        <ul>
                          <li>
                            <a
                              href="https://ethiopiancalendar.wordpress.com/history/"
                              target="_blank"
                            >
                              The Ethiopic Calendar
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://theculturetrip.com/africa/ethiopia/articles/why-is-the-ethiopian-calendar-7-years-behind/"
                              target="_blank"
                            >
                              Why Is the Ethiopian Calendar 7 Years Behind?
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <hr></hr>
              </div>
            </div>
          </div>

          <div className="container ">
            <div className="row ">
              <div className="container ">
                <div className="row ">
                  <div className=" col-md-8 ">
                    <div
                      className="downarrowdiv text-center "
                      id=""
                      style={{ width: "100%" }}
                    >
                      <h1 className=" font-weight-bold">
                        <span className="text-white  font-weight-bold">
                          Feed the Hungry
                          <span className="text-warning">
                            &nbsp;Easter Meal Campaign 2014
                          </span>
                        </span>
                      </h1>
                    </div>
                    <div className="bg-light text-white p-4">
                      <p className="lead text-justify">
                        Hope 4 Tebassie is currently raising funds to provide
                        Easter meals for the Tebassie community in Debre Birhan
                        Ethiopia. Our Easter feed the poor target is to reach
                        1000 individuals. We believe that with your generosity
                        and empathy for those who are in need, we can provide
                        meals to 1000 individuals. In view of this, your
                        donation of $25,$50, $75, $100 or whatever your heart
                        desires will be greatly appreciated. We thank you in
                        advance for supporting this blessed initiative and for
                        fulfilling our moral obligations. We thank you for your
                        support!
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-4 py-5 mt-5">
                    <ProgressBar
                      animated
                      now={(5200 / 10000) * 100}
                      label="Closed"
                      style={{
                        width: "100%",
                        height: "30px",
                      }}
                    />
                    <div
                      className="text-warning font-weight-bold"
                      style={{ float: "left" }}
                    >
                      <em style={{ fontFamily: "Courier" }}>
                        Raised:$8540.20 (
                        {`${((8540.2 / 7000) * 100).toPrecision(3)}%`})
                        {/*( {`${((2400 / 10000) * 100).toPrecision(4)}%`}) */}
                      </em>
                    </div>
                    <div
                      className="text-warning font-weight-bold"
                      style={{ float: "right" }}
                    >
                      <em style={{ fontFamily: "Courier" }}>Goal: $7,000.00</em>
                      <br></br>
                      <br></br>

                      <img
                        src={easter}
                        width="100%"
                        height="250px"
                        style={{
                          borderradius: "35px 0 0 0",
                        }}
                      />

                      {/* <img
                    src={easter}
                    alt=""
                    style={{ height: "100%", width: "100%", align: "center" }}
                  /> */}
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <div className=" col-md-8 ">
                <div
                  className="downarrowdiv text-center "
                  id=""
                  style={{ width: "100%" }}
                >
                  <h1 className=" font-weight-bold">
                    <span className="text-white  font-weight-bold">
                      Hunger Free
                      <span className="text-warning">
                        &nbsp; Enkutatash 2014 &nbsp;Campaign
                      </span>
                    </span>
                  </h1>
                </div>
                <div className="bg-light text-white p-4">
                  <p className="lead text-justify">
                    Our second annual “Enkutatash” Ethiopian's New Year
                    fundraising campaign! We want to start by saying thanks a
                    million to our generous donors for supporting H4T’s mission
                    in providing aid to disadvantaged families – mostly families
                    who were affected by two protracted wars.
                  </p>
                  <p className="lead text-justify">
                    Unfortunately, the current conflict has compounded the
                    challenge on the ground and our fundraising effort needs to
                    be expanded to include families who are impacted by the
                    current conflict and those who are internally displaced and
                    relocated from war zones to Tebassie in recent months. To
                    meet these challenges, our fundraising goal for this year is
                    to raise $10,000 and provide our annual holiday meals to all
                    who are in need.
                  </p>
                  <p className="lead text-justify">
                    Let’s do this so no family will go to bed starved during the
                    Enkutatash holiday. We thank you for your support!
                  </p>
                </div>
              </div>

              <div className="col-sm-4 py-5 mt-5">
                <ProgressBar
                  animated
                  now={(5000 / 10000) * 100}
                  // label={` ${((5000 / 10000) * 100).toPrecision(3)} %`}
                  label="Closed"
                  style={{
                    width: "100%",
                    height: "30px",
                  }}
                />
                <div
                  className="text-primary font-weight-bold"
                  style={{ float: "left" }}
                >
                  <em style={{ fontFamily: "Courier" }}>
                    Raised: $5,000.63 ({" "}
                    {`${((5000 / 10000) * 100).toPrecision(3)}%`})
                  </em>
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  <em style={{ fontFamily: "Courier" }}>Goal: $10,000.00</em>
                </div>

                <div
                  className="text-warning font-weight-bold"
                  style={{ float: "right" }}
                ></div>
                <br></br>
                <br></br>
                <img
                  src={newyear}
                  alt=""
                  style={{ height: "100%", width: "100%", align: "center" }}
                />
              </div>

              <div className="row p-0">
                <div className="col-md-2 p-1 ">
                  <img
                    src={dyk}
                    alt=""
                    style={{ height: "100%", width: "100%", align: "center" }}
                  />
                </div>
                <div className="col-md-10 m-0 p-0 bg-white">
                  <h6 className="lead text-justify p-3 ">
                    Ethiopia is 7 years behind and the first month of the
                    Calendar year is September? Yes! Ethiopia will be welcoming
                    2013 on September 11, 2020. See links below for more
                    information regarding the history Ethiopian calendar.
                  </h6>
                  <ul>
                    <li>
                      <a
                        href="https://ethiopiancalendar.wordpress.com/history/"
                        target="_blank"
                      >
                        The Ethiopic Calendar
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://theculturetrip.com/africa/ethiopia/articles/why-is-the-ethiopian-calendar-7-years-behind/"
                        target="_blank"
                      >
                        Why Is the Ethiopian Calendar 7 Years Behind?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div className="container ">
            <div className="row ">
              <div className=" col-md-8 ">
                <div
                  className="downarrowdiv text-center "
                  id=""
                  style={{ width: "100%" }}
                >
                  <h1 className=" font-weight-bold">
                    <span className="text-white  font-weight-bold">
                      Feed the Hungry
                      <span className="text-warning">
                        &nbsp;Easter Meal Campaign 2013
                      </span>
                    </span>
                  </h1>
                </div>
                <div className="bg-light text-white p-4">
                  <p className="lead text-justify">
                    Hope 4 Tebassie is currently raising funds to provide Easter
                    meals for the Tebassie community in Debre Birhan Ethiopia.
                    For Ethiopian New Year, with the help of individuals like
                    you we were able to raise a little over $4,500 and provided
                    meals to 110 households or about 500 individuals. With your
                    help, we are hoping that we can feed more people during the
                    Easter celebration.
                  </p>
                  <p className="lead text-justify">
                    Our Easter feed the poor target is to reach 1000
                    individuals. This is a huge number, but we believe that with
                    your generosity and empathy for those who are in need, we
                    can provide meals to 1000 individuals. In view of this, your
                    donation of $25,$50, $75, $100 or whatever your heart
                    desires will be greatly appreciated.
                  </p>
                  <p className="lead text-justify">
                    We thank you in advance for supporting this blessed
                    initiative and for fulfilling our moral obligations. We
                    thank you for your support!
                  </p>
                </div>
              </div>

              <div className="col-sm-4 py-5 mt-5">
                <ProgressBar
                  animated
                  now={(5200 / 10000) * 100}
                  label="Closed"
                  style={{
                    width: "100%",
                    height: "30px",
                  }}
                />
                <div
                  className="text-warning font-weight-bold"
                  style={{ float: "left" }}
                >
                  <em style={{ fontFamily: "Courier" }}>
                    Raised: $5,200 (
                    {` ${((5200 / 10000) * 100).toPrecision(4)} %`})
                    {/*( {`${((2400 / 10000) * 100).toPrecision(4)}%`}) */}
                  </em>
                </div>
                <div
                  className="text-warning font-weight-bold"
                  style={{ float: "right" }}
                >
                  <em style={{ fontFamily: "Courier" }}>Goal: $10,000.00</em>
                  <br></br>
                  <br></br>

                  <video
                    controls
                    src={vicemayor__speech}
                    style={{ height: "100%", width: "100%", align: "center" }}
                  ></video>

                  {/* <img
                    src={easter}
                    alt=""
                    style={{ height: "100%", width: "100%", align: "center" }}
                  /> */}
                </div>
              </div>

              <br />
            </div>
          </div>

          <div className="container ">
            <div className="row ">
              <div className=" col-md-8 ">
                <div
                  className="downarrowdiv text-center "
                  id=""
                  style={{ width: "100%" }}
                >
                  <h1 className=" font-weight-bold">
                    <span className="text-white  font-weight-bold">
                      Hunger Free
                      <span className="text-warning">
                        &nbsp; Enkutatash 2013 &nbsp;Campaign
                      </span>
                    </span>
                  </h1>
                </div>
                <div className="bg-light text-white p-4">
                  <p className="lead text-justify">
                    Our first fundraising since becoming licensed is focused to
                    raise funds to provide meals for the Holiday, Ethiopian New
                    Year, “Enkutatash 2013” . We are most fortunate to not have
                    to worry about where our next meal is coming from, and we
                    hope we will never have to be in such an agonizing
                    situation. However, in Tebassie Ethiopia there are many
                    families who must choose between feeding their children or
                    themselves.
                  </p>
                  <p className="lead text-justify">
                    We at H4T, while we understand we can’t change our
                    community’s lives in a day or even in a year, we are
                    committed to do what we can one day at a time with the help
                    of individuals like you. That is why we decided to kick off
                    this great venture at the start of the Ethiopian New Year,
                    Enkutatash 2013 fundraising campaign. Help us to assist
                    families to have a holiday meal and put a smile on their
                    face.
                  </p>
                  <p className="lead text-justify">
                    We thank you in advance for supporting our efforts! We
                    cannot do this without generous friends and family like you.
                  </p>
                </div>
              </div>

              <div className="col-sm-4 py-5 mt-5">
                <ProgressBar
                  animated
                  now={(4476.76 / 5000) * 100}
                  // label={` ${((4476.76 / 5000) * 100).toPrecision(4)} %`}
                  label="Closed"
                  style={{
                    width: "100%",
                    height: "30px",
                  }}
                />
                <div
                  className="text-warning font-weight-bold"
                  style={{ float: "left" }}
                >
                  <em style={{ fontFamily: "Courier" }}>
                    Raised: $4,476.76 (
                    {`${((4476.76 / 5000) * 100).toPrecision(4)}%`})
                  </em>
                </div>
                <div
                  className="text-warning font-weight-bold"
                  style={{ float: "right" }}
                >
                  <em style={{ fontFamily: "Courier" }}>Goal: $5,000.00</em>
                </div>
                <br></br>
                <br></br>
                <img
                  src={newyear}
                  alt=""
                  style={{ height: "100%", width: "100%", align: "center" }}
                />
              </div>

              <div className="row p-0">
                <div className="col-md-2 p-1 ">
                  <img
                    src={dyk}
                    alt=""
                    style={{ height: "100%", width: "100%", align: "center" }}
                  />
                </div>
                <div className="col-md-10 m-0 p-0 bg-white">
                  <h6 className="lead text-justify p-3 ">
                    Ethiopia is 7 years behind and the first month of the
                    Calendar year is September? Yes! Ethiopia will be welcoming
                    2013 on September 11, 2020. See links below for more
                    information regarding the history Ethiopian calendar.
                  </h6>
                  <ul>
                    <li>
                      <a
                        href="https://ethiopiancalendar.wordpress.com/history/"
                        target="_blank"
                      >
                        The Ethiopic Calendar
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://theculturetrip.com/africa/ethiopia/articles/why-is-the-ethiopian-calendar-7-years-behind/"
                        target="_blank"
                      >
                        Why Is the Ethiopian Calendar 7 Years Behind?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
          </div>
        </CausesContainer>
      </div>
    );
  }
}

export default Causes;

const CausesContainer = styled.section`
  * {
    color: #000;
  }
  h4 {
    text-align: left;
    padding: 10px;
    color: #121214;
    width: 25%;
    :hover {
      opacity: 0.5;
    }
  }
  img {
    :hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  kbd {
    color: var(--mainYellow);
  }
`;
