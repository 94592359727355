import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
const PUBLIC_KEY =
  "pk_live_51IdHtHBNbE8tP4g1GgnMeVA5XolW8BQw2M8TRrAZbMwB585tqpepJ3JwcjxFnsvoC1lMCUewqrQxzxDMqzZhYVDq00QYGqJLaA";

const stripeTPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer() {
  return (
    <>
      <Elements stripe={stripeTPromise}>
        <PaymentForm />
      </Elements>
    </>
  );
}
