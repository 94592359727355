import React, { Component } from "react";
import CountUp from "react-countup";
import AnimatedNumber from "animated-number-react";

import "./Dashboard.css";

export class Dashboard extends Component {
  formatValue1 = (value) => ` ${Number(value).toFixed(0)}`;

  formatValue2 = (value) =>
    `${Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}`;

  render() {
    return (
      // <div className="divdash">
      //   <div className="dashboard">lorem</div>
      //   <div className="dashboard">epsum</div>
      //   <div className="dashboard">yonael</div>
      // </div>
      <center>
        {/* <a href="https://buy.stripe.com/bIY8Ak2KM2YT24g3cc">Donate Here</a> */}
        <div className="row">
          <div className="dashboard ">
            <AnimatedNumber
              value={5}
              formatValue={this.formatValue1}
              duration={600}
            />
            <br />
            Campaigns
          </div>
          <div className="dashboard ">
            <AnimatedNumber
              value={30293.59}
              formatValue={this.formatValue2}
              duration={1800}
            />
            <br />
            Raised
          </div>
          <div className="dashboard">
            <AnimatedNumber
              value={2000}
              formatValue={this.formatValue1}
              duration={1800}
            />
            + Individuals Assisted
          </div>
        </div>
      </center>
    );
  }
}

export default Dashboard;
