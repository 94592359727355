import React, { Component } from "react";

import Image1 from "../../images/dbu.jpg";
import Image2 from "../../images/localmarket.jpg";
import Image3 from "../../images/beresa.jpg";
import Image4 from "../../images/rasabebe.png";
import logo from "../../images/logo11.png";
import hope from "../../images/hope.jpg";
import zelle from "../../images/zelle.png";
import ThankYou from "./ThankYou";
import ProgressBar from "react-bootstrap/ProgressBar";
import newyear from "../../images/newyear.jpg";

import "./Home.css";
import { Wave } from "react-animated-text";
import Anti_Hunger_Initiative from "./Anti-Hunger-Initiative";
import NewYear from "./NewYear";
import Easter from "./Easter";
import Give828 from "./Give828";

import Dashboard from "./Dashboard";

export default class Home extends Component {
  render() {
    return (
      <>
        <div className=" home bg-light justify-content-center">
          <div className="home__container">
            <img className="home__image" src={hope} alt="" />

            {/* <div className="m-5 p-5 bg-white">
              <ThankYou />
            </div> */}
            <div className="row home__content d-flex justify-content-center ">
              <div
                className="col-lg-3 home__row lead text-justify rounded border bg-dark text-white"
                style={{
                  borderLeft: "50px solid #FFC30C",
                  color: "#000",
                  fontWeight: "normal",
                  width: "100%",
                }}
              >
                {/* {<Give828 />} */}
                <p className="animate_p lead justify rounded">
                  <span style={{ fontWeight: "bold" }}>
                    Hope 4 Tebassie (H4T)&nbsp;
                  </span>
                  is a non-profit 501(c)(3) organization registered in the state
                  of Maryland. We are devoted to giving hope for most vulnerable
                  groups in our hometown Tebassie, Debre Birhan, Ethiopia. We
                  are a USA based Non-Governmental Organization (NGO) that
                  offers support to most vulnerable groups in our hometown to
                  gain access to health services, clean water, and education.
                </p>
                {/* <p className="lead justify rounded">
                  Founded by the Tebassie Hometown Association (HTA) group, we
                  understand the need of our hometown and the work ahead of us
                  to provide aid in communities where the capacity of the local
                  government has fallen short.
                </p>
                <p className="lead justify rounded">
                  We are confident that with our unwavering support, we aspire
                  to engage the local community in battling and eradicating
                  poverty and expanding opportunities for those disregarded and
                  most vulnerable.
                </p> */}
              </div>
              <div
                className="col-lg-7 home__div home__row lead text-justify rounded  text-dark"
                style={{
                  borderLeft: "1px solid #FFC30C",
                  borderRadius: "25px",
                  color: "#000",
                  fontWeight: "normal",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              >
                {/* <ThankYou /> */}
                {/* <NewYear /> */}
                <Easter />
                <br />
                <br />
                <br />
                <Anti_Hunger_Initiative />
              </div>
            </div>

            {/* <div className="bg-warning">
              <Dashboard></Dashboard>
            </div> */}
          </div>
        </div>

        <div className="bg-warning home__image1" style={{ opacity: 0.7 }}>
          <Dashboard></Dashboard>
        </div>
      </>
    );
  }
}
